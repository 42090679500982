// @ts-nocheck
import { useState } from 'react'
import {
  Icon,
  Box,
  SimpleGrid,
  Heading,
  Flex,
  Stack,
  Tag,
  TagLabel,
  TagCloseButton,
  useColorModeValue,
} from '@chakra-ui/react'
import { FaCheck } from 'react-icons/fa'
import { MdAdd, MdGridOn, MdFormatListBulleted } from 'react-icons/md'
import { motion } from 'framer-motion'
import { HorizontalArticle } from '@astrolab/web/components/news/HorizontalArticle'
import { VerticalArticle } from '@astrolab/web/components/news/VerticalArticle'
import { HSeparator } from '@astrolab/web/common/Separator'
import { useConfig } from 'nextra-theme-docs'
import { useRouter } from 'nextra/hooks'


const getLocalizedBlogPosts = (locale, config) => {
  const {
    docsDirectories,
  } = config.normalizePagesResult
  const route = `/${locale}/blog/`
  const blogPosts = docsDirectories.filter(page => page.route.startsWith(route))
  return blogPosts
}

export function Blog({
  title,
  featuredArticles,
  latestArticles,
}: {
  title: string
  featuredArticles: string
  latestArticles: string
}) {
  const locale = useRouter().locale
  const config = useConfig()
  const MotionBox = motion(Box)
  const [selectedTopics, setSelectedTopics] = useState([])
  const [layoutIsGrid, setlayoutIsGrid] = useState(false)
  const textColor = useColorModeValue('secondaryGray.900', 'white')
  const tagBackgroundColor = 'secondaryGray.900'
  const tagColor = 'white' // useColorModeValue('secondaryGray.100', 'secondaryGray.100')

  const articles = getLocalizedBlogPosts(locale, config)
    .filter((article) => article.frontMatter?.archived != true)
    .filter(
      (article) =>
        selectedTopics.length == 0 ||
        selectedTopics.some((tag) => article.frontMatter?.tags?.includes(tag))
    )
    .sort(
      (a, b) =>
        b.frontMatter?.date ? b.frontMatter.date - a.frontMatter.date : 0 // ensure posts are sorted by date
    )
    .map((page, index) =>
      page.name == 'index'
        ? null
        : {
            title: page.frontMatter?.title || page.name,
            description: page.frontMatter?.description,
            link: page.route,
            banner: page.frontMatter?.image,
            author: 'The OctoBot team',
            difficultyLevel: page.frontMatter?.difficulty_level,
            tags: page.frontMatter?.tags,
            readingTime: page.frontMatter?.reading_time,
            date: new Date(page.frontMatter?.date),
          }
    )

  const featuredArticlesRoute = [
    'mobile-app-revamp',
    'introducing-chatgpt-trading-tool',
  ]

  return (
    <Box w={{ base: '100%', xl: '130%' }} ms={{ base: '0px', xl: '-20%' }}>
      <Heading as="h1" mb="10px">
        {title}
      </Heading>
      <Flex direction="row" mb="20px">
        <Flex direction="column" w="100%">
          <Flex direction="column" gap={2} mb="40px">
            <Heading as="h2" fontSize="3xl" my="5px">
              {featuredArticles}
            </Heading>
            <Box w="100%">
              <SimpleGrid
                minChildWidth={{ base: '270px', md: '350px' }}
                w="80%"
                spacing="10px"
                mx={{base: "0", sm: "auto"}}
              >
                {featuredArticlesRoute.map((articleRoute, index) => {
                  const article = articles.find(
                    (article) => article?.link.endsWith(`/${articleRoute}`)
                  )
                  return article ? (
                    <MotionBox
                      key={index}
                      whileHover={{
                        scale: 1.02,
                      }}
                      transition={{ duration: 0.2 }}
                    >
                      <VerticalArticle article={article} mx="auto" />
                    </MotionBox>
                  ) : null
                })}
              </SimpleGrid>
            </Box>
          </Flex>
          <HSeparator my="30px" />
          <Flex direction="column" gap={2}>
            <Heading as="h2" fontSize="3xl" my="5px">
              {latestArticles}
            </Heading>
            <Flex direction="row" gap={2}>
              <Stack spacing="5px" wrap="wrap" direction="row">
                {[
                  'Strategy',
                  'AI',
                  'Educational',
                  'Backtesting',
                  'Release',
                ].map((name, index) => {
                  const isSelected = selectedTopics.includes(name)
                  return (
                    <Tag
                      size="md"
                      key={index}
                      borderRadius="full"
                      variant="solid"
                      textColor={tagColor}
                      backgroundColor={tagBackgroundColor}
                      cursor="pointer"
                      fontWeight={isSelected ? '700' : '500'}
                      onClick={() =>
                        isSelected
                          ? setSelectedTopics([
                              ...selectedTopics.filter(
                                (topic) => topic != name
                              ),
                            ])
                          : setSelectedTopics([...selectedTopics, name])
                      }
                      data-ph-capture-attribute-button-role="blogTagsFilter"
                      data-ph-capture-attribute-filtered-tag-name={name}
                    >
                      <TagLabel>{name}</TagLabel>
                      {isSelected ? (
                        <TagCloseButton as={FaCheck} w="12px" me="5px" />
                      ) : (
                        <TagCloseButton as={MdAdd} w="20px" me="5px" />
                      )}
                    </Tag>
                  )
                })}
              </Stack>
              <Stack
                my="auto"
                ms="auto"
                me="3%"
                spacing="10px"
                wrap="wrap"
                direction="row"
                display={{ base: 'none', md: 'flex' }}
              >
                <Icon
                  as={MdGridOn}
                  w={5}
                  h={5}
                  cursor="pointer"
                  color={layoutIsGrid ? textColor : 'secondaryGray.600'}
                  onClick={() => setlayoutIsGrid(true)}
                />
                <Icon
                  as={MdFormatListBulleted}
                  w={5}
                  h={5}
                  cursor="pointer"
                  color={layoutIsGrid ? 'secondaryGray.600' : textColor}
                  onClick={() => setlayoutIsGrid(false)}
                />
              </Stack>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Box
        w="100%"
        display={{ base: 'none', md: layoutIsGrid ? 'none' : 'flex' }}
      >
        <SimpleGrid minChildWidth="700px" w="100%" spacing="20px">
          {articles.map((article, index) =>
            article ? (
              <MotionBox
                key={index}
                whileHover={{
                  scale: 1.02,
                }}
                transition={{ duration: 0.2 }}
              >
                <HorizontalArticle article={article} w="97%" />
              </MotionBox>
            ) : null
          )}
        </SimpleGrid>
      </Box>
      <Box
        w="100%"
        display={{ base: 'flex', md: layoutIsGrid ? 'flex' : 'none' }}
      >
        <SimpleGrid 
          minChildWidth={{ base: '270px', md: '350px' }}
          w="100%" 
          spacing="10px"
          mx={{base: "0", sm: "auto"}}
        >
          {articles.map((article, index) =>
            article ? (
              <MotionBox
                key={index}
                whileHover={{
                  scale: 1.02,
                }}
                transition={{ duration: 0.2 }}
              >
                <VerticalArticle article={article} mx="auto" />
              </MotionBox>
            ) : null
          )}
        </SimpleGrid>
      </Box>
    </Box>
  )
}
